/**
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as types from '@/store/mutation-types/frontend-types'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, FreePage } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/rakuten_id_connect`

interface RakutenInfoResult extends BaseResult {
  content: {
    // id_connects: FreePage[]
  }
}

const idConnect = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    show({}, { code, is_new }: { code: string, is_new: boolean}) {
      const params = {
        code: code,
        is_new: is_new,
      }
      const api_path = `${API_PATH}`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path, { params: params }).then((data: RakutenInfoResult) => {
          resolve(data.content)
        })
      })
    },
  },
}

export default idConnect
