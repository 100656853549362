import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import loading from '@/store/modules/loading'
import snackbar from '@/store/modules/snackbar'
import alertMessage from '@/store/modules/alert-message'
import frontendModules from '@/store/modules/frontend'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    loading,
    snackbar,
    alertMessage,
    frontendModules,
  },
  strict: debug,
})
