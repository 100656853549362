import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/plugins/filter'
import '@/plugins/vee-validate'
import httpClient from './plugins/http-client'
import VueHead from 'vue-head'
import Vuetify from 'vuetify'
import ja from 'vuetify/src/locale/ja'
import 'vuetify/dist/vuetify.min.css'
import './tailwind.css'

import * as VueGoogleMaps from 'vue2-google-maps'

import { Icon } from 'leaflet'

type D = Icon.Default & {
  _getIconUrl?: string
}

declare global {
  interface Window {
    gtag_report_conversion(url?: string): boolean
  }
}

delete (Icon.Default.prototype as D)._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.use(VueHead)

Vue.use(Vuetify)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_API,
    libraries: 'places',
    region: 'JP',
    language: 'ja',
  },
})

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { ja },
    current: 'ja',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#127A8E',
      },
    },
  },
})

Vue.config.productionTip = false
Vue.use(httpClient, { store })

Vue.config.errorHandler = function (error) {
  if (error.name === 'NavigationDuplicated') {
    // routerで遷移する時、同じページに遷移しようとすると起こるエラーを回避
    return
  }
}

new Vue({
  router,
  store,
  vuetify: vuetify,
  render: h => h(App),
}).$mount('#app')
