/**
 * カート
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as types from '@/store/mutation-types/frontend-types'
import * as httpConst from '@/constants/httpConst'
import { BaseResult } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/carts`

interface BaseCart {
  carts: Cart[]
  total_price: number
  postage: number
  total_amount: number
}

interface PaymentResult extends BaseResult {
  content: {
    pay_form_url: string
  }
}

interface CartResult extends BaseResult {
  content: BaseCart
}
interface CartCountResult extends BaseResult {
  content: {
    cart_count: number
  }
}
interface CartDeliveryResult extends BaseResult {
  content: {
    id: number
  }
}
interface ConfirmCartDaysResult extends BaseResult {
  content: {
    error_message: string
  }
}

interface Cart {
  id: number
}
interface CartState {
  cartCount: number
  content: BaseCart
}

interface CartParam {
  product_id: number
  quantity: number
  schedule_date: string
  product_type: string
  return_date: string
  roadside_station_product_id: number
}

interface UpdateCartOrderParam {
  schedule_date: string
  return_date: string
  station_schedule_date: string
  station_schedule_time: string
  use_security_service: boolean
  remarks: string | null
}

interface UpdateDeliveryParam {
  name: string
  zip_code: string
  prefecture_code: string
  address: string
  phone: string
  desired_time_value: string | null
}


const cart = {
  namespaced: true,
  state: {
    cartCount: 0,
    content: {},
  },
  getters: {
    isCarts: (state: CartState) => {
      return state.content.carts && state.content.carts.length > 0
    },
  },
  actions: {
    resetCount({ commit }: { commit: Commit }) {
      commit(types.CART_COUNT, { count: 0 })
    },
    index({ commit }: { commit: Commit }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(API_PATH)
          .then((data: CartResult) => {
            commit(types.CART, { data })
            resolve(data.content)
          })
          .catch(() => {
            reject()
          })
      })
    },
    fetchCount({ commit }: { commit: Commit }) {
      const api_path = `${API_PATH}/count`
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(api_path)
          .then((data: CartCountResult) => {
            const content = data.content
            commit(types.CART_COUNT, { count: content.cart_count })
            resolve(content.cart_count)
          })
          .catch(() => {
            reject()
          })
      })
    },
    fetchPayment({}) {
      // 支払情報取得
      const api_path = `${API_PATH}/payment`
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(api_path)
          .then((data: PaymentResult) => {
            const content = data.content
            resolve(content)
          })
          .catch(() => {
            reject()
          })
      })
    },
    checkPayment({}) {
      // SBペイメントへの注文前チェック
      const api_path = `${API_PATH}/payment/edit`
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(api_path)
          .then((data: BaseResult) => {
            resolve(data)
          })
          .catch(() => {
            reject()
          })
      })
    },
    create({ commit }: { commit: Commit }, { params }: { params: CartParam }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(API_PATH, params)
          .then((data: BaseResult) => {
            commit('snackbar/showInfo', 'カートに追加しました', { root: true })
            resolve(data)
          })
          .catch(() => {
            reject()
          })
      })
    },
    update({ commit }: { commit: Commit }, { id, quantity }: { id: number; quantity: number }) {
      const params = {
        quantity: quantity,
      }
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(api_path, params)
          .then((data: BaseResult) => {
            commit('snackbar/showInfo', '更新しました', { root: true })
            resolve(data)
          })
          .catch(() => {
            reject()
          })
      })
    },
    delete({ commit }: { commit: Commit }, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(api_path)
          .then((data: BaseResult) => {
            commit('snackbar/showInfo', '削除しました', { root: true })
            resolve(data)
          })
          .catch(() => {
            reject()
          })
      })
    },
    updateCartOrder({}, { params }: { params: UpdateCartOrderParam }) {
      const api_path = `${API_PATH}/order`
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(api_path, params)
          .then((data: BaseResult) => {
            resolve(data)
          })
          .catch(() => {
            reject()
          })
      })
    },
    showCartDelivery({}) {
      const api_path = `${API_PATH}/delivery`
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(api_path)
          .then((data: CartDeliveryResult) => {
            const content = data.content
            resolve(content)
          })
          .catch(() => {
            reject()
          })
      })
    },
    confirmationCartDates({}) {
      const api_path = `${API_PATH}/confirmation`
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(api_path)
          .then((data: ConfirmCartDaysResult) => {
            const content = data.content
            resolve(content)
          })
          .catch(() => {
            reject()
          })
      })
    },
    updateCartDelivery({}, { params }: { params: UpdateDeliveryParam }) {
      const api_path = `${API_PATH}/delivery`
      const parameter = {
        camp_site_id: null as number | null,
        roadside_station_id: null as number | null,
        delivery_name: params.name as string,
        delivery_zip_code: params.zip_code as string,
        delivery_prefecture_code: params.prefecture_code as string,
        delivery_address: params.address as string,
        delivery_phone: params.phone as string,
        delivery_desired_time_value: params.desired_time_value as string | null,
      }

      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .put(api_path, parameter)
          .then((data: BaseResult) => {
            resolve(data)
          })
          .catch(() => {
            reject()
          })
      })
    },
  },
  mutations: {
    [types.CART_COUNT](state: CartState, { count }: { count: number }) {
      state.cartCount = count
    },
    [types.CART](state: CartState, { data }: { data: CartResult }) {
      state.content = data.content
    },
  },
}

export default cart
