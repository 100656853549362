/**
 * 店舗からのお知らせ
 */
import Vue from 'vue'
import * as types from '@/store/mutation-types/frontend-types'
import { Commit } from 'vuex'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, Pagination } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/customer/notices`

interface Notice {
  id: number
}

interface NoticeResult extends BaseResult {
  content: {
    notices: Notice[]
    pagination: Pagination
  }
}
interface NoticeDetailResult extends BaseResult {
  content: Notice
}

interface NoticeState {
  pagination: Pagination
}

const customerNotice = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total_count: 0,
    },
  },
  getters: {
    isCustomerNotice: (state: NoticeState) => {
      return state.pagination.total_count > 0
    },
  },
  actions: {
    index({ commit }: { commit: Commit }, { page }: { page: number }) {
      const params = {
        page: page,
      }
      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH, { params: params }).then((data: NoticeResult) => {
          commit(types.CUSTOMER_NOTICE, { data })
          resolve(data.content.notices)
        })
      })
    },
    show({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: NoticeDetailResult) => {
          resolve(data.content)
        })
      })
    },
  },
  mutations: {
    [types.CUSTOMER_NOTICE](state: NoticeState, { data }: { data: NoticeResult }) {
      state.pagination = data.content.pagination
    },
  },
}

export default customerNotice
