/**
 * 新着情報（TOPICS）
 */
import Vue from 'vue'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, FreePage, Pagination } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/topics`

interface TopicResult extends BaseResult {
  content: {
    topics: FreePage[]
    pagination: Pagination
  }
}

const topic = {
  namespaced: true,
  state: {
    pagination: {},
  },
  getters: {},
  actions: {
    show({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: TopicResult) => {
          resolve(data.content)
        })
      })
    },
    recent_posts({}) {
      const api_path = `${API_PATH}/recent_posts`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: TopicResult) => {
          resolve(data.content.topics)
        })
      })
    },
  },
  mutations: {},
}

export default topic
