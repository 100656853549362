import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeIndex from '@/views/home/Index.vue'
import VueGtag from 'vue-gtag'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeIndex,
  },
  {
    path: '/news/:id',
    name: 'news-show',
    component: () => import(/* webpackChunkName: "news" */ '../views/news/Show.vue'),
  },
  {
    path: '/topics',
    name: 'topics',
    component: () => import(/* webpackChunkName: "topics" */ '../views/topics/Index.vue'),
  },
  {
    path: '/topics/:id',
    name: 'topics-show',
    component: () => import(/* webpackChunkName: "topics-show" */ '../views/topics/Show.vue'),
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '../views/products/Index.vue'),
  },
  {
    path: '/products/categories',
    name: 'products-categories',
    component: () => import(/* webpackChunkName: "products-categories" */ '../views/products/Categories.vue'),
  },
  {
    path: '/products/set',
    name: 'products-set',
    component: () => import(/* webpackChunkName: "products-set" */ '../views/products/Set.vue'),
  },
  {
    path: '/products/:id',
    name: 'products-show',
    component: () => import(/* webpackChunkName: "products-show" */ '../views/products/Show.vue'),
  },
  {
    path: '/user_data/rentalspot/campsite',
    name: 'camp-sites',
    component: () => import(/* webpackChunkName: "camp-sites" */ '@/views/camp_site/Index.vue'),
  },
  {
    path: '/user_data/rentalspot',
    name: 'roadside-stations',
    component: () => import(/* webpackChunkName: "roadside-stations" */ '@/views/roadside_stations/Index.vue'),
  },
  {
    path: '/user_data/rentalspot/:id',
    name: 'roadside-stations-show',
    component: () => import(/* webpackChunkName: "roadside-stations-show" */ '@/views/roadside_stations/Show.vue'),
  },
  {
    path: '/user_data/guide',
    name: 'guide',
    component: () => import(/* webpackChunkName: "guide" */ '@/views/guide/Index.vue'),
  },
  // {
  //   path: '/user_data/coupon_detail',
  //   name: 'coupon',
  //   component: () => import(/* webpackChunkName: "coupon" */ '@/views/coupon/Index.vue'),
  // },
  {
    path: '/beginners-camp',
    name: 'beginners-camp',
    component: () => import(/* webpackChunkName: "beginners-camp" */ '@/views/beginners-camp/Index.vue'),
  },
  {
    path: '/user_data/carrier',
    name: 'carrier',
    component: () => import(/* webpackChunkName: "carrier" */ '@/views/carrier/Index.vue'),
  },
  {
    path: '/user_data/qa',
    name: 'qa',
    component: () => import(/* webpackChunkName: "qa" */ '@/views/qa/Index.vue'),
  },
  {
    path: '/help/tradelaw',
    name: 'tradelaw',
    component: () => import(/* webpackChunkName: "tradelaw" */ '@/views/tradelaw/Index.vue'),
  },
  {
    path: '/help/agreement',
    name: 'agreement',
    component: () => import(/* webpackChunkName: "agreement" */ '@/views/agreement/Index.vue'),
  },
  {
    path: '/help/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '@/views/privacy/Index.vue'),
  },
  {
    path: '/inquiry',
    name: 'inquiry',
    component: () => import(/* webpackChunkName: "inquiry" */ '@/views/inquiry/Index.vue'),
  },
  {
    path: '/inquiry/completed',
    name: 'inquiry-completed',
    component: () => import(/* webpackChunkName: "inquiry" */ '@/views/inquiry/Completed.vue'),
  },
  {
    path: '/sessions',
    name: 'session-index',
    component: () => import(/* webpackChunkName: "session-index" */ '@/views/sessions/Index.vue'),
  },
  {
    path: '/identification/edit',
    name: 'membership-identification-edit',
    component: () => import(/* webpackChunkName: "membership-identification" */ '@/views/membership/identification/Edit.vue'),
  },
  {
    path: '/notifications',
    name: 'membership-notification-index',
    component: () => import(/* webpackChunkName: "membership-notification" */ '@/views/membership/notifications/Index.vue'),
  },
  {
    path: '/tickets',
    name: 'membership-ticket-index',
    component: () => import(/* webpackChunkName: "membership-ticket" */ '@/views/membership/tickets/Index.vue'),
  },
  {
    path: '/tickets/:id',
    name: 'membership-ticket-edit',
    component: () => import(/* webpackChunkName: "membership-ticket" */ '@/views/membership/tickets/Edit.vue'),
  },
  {
    path: '/profile/new',
    name: 'membership-profile-new',
    component: () => import(/* webpackChunkName: "membership-profile" */ '@/views/membership/profile/New.vue'),
  },
  {
    path: '/profile/edit',
    name: 'membership-profile-edit',
    component: () => import(/* webpackChunkName: "membership-profile" */ '@/views/membership/profile/Edit.vue'),
  },
  {
    path: '/profile/completed',
    name: 'membership-profile-completed',
    component: () => import(/* webpackChunkName: "membership-profile" */ '@/views/membership/profile/Completed.vue'),
  },
  {
    path: '/histories',
    name: 'histories',
    component: () => import(/* webpackChunkName: "histories" */ '@/views/membership/histories/Index.vue'),
  },
  {
    path: '/histories/:id',
    name: 'histories-show',
    component: () => import(/* webpackChunkName: "histories" */ '@/views/membership/histories/Show.vue'),
  },
  {
    path: '/activate/completed',
    name: 'activate-completed',
    component: () => import(/* webpackChunkName: "membership-profile" */ '@/views/membership/activate/Completed.vue'),
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import(/* webpackChunkName: "forget" */ '@/views/forget/Index.vue'),
  },
  {
    path: '/forget/sent',
    name: 'forget-sent',
    component: () => import(/* webpackChunkName: "forget" */ '@/views/forget/Sent.vue'),
  },
  {
    path: '/forget/:token',
    name: 'forget-edit',
    component: () => import(/* webpackChunkName: "forget" */ '@/views/forget/Edit.vue'),
  },
  {
    path: '/forget/completed',
    name: 'forget-completed',
    component: () => import(/* webpackChunkName: "forget" */ '@/views/forget/Completed.vue'),
  },
  {
    path: '/carts',
    name: 'cart-index',
    component: () => import(/* webpackChunkName: "carts" */ '@/views/carts/Index.vue'),
  },
  {
    path: '/carts/completed/:order_number',
    name: 'cart-completed',
    component: () => import(/* webpackChunkName: "carts" */ '@/views/carts/Completed.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) return { offset: { x: 0, y: 90 }, selector: to.hash }
    return { x: 0, y: 0 }
  },
})

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GA_ID },
  },
  router,
)

export default router
