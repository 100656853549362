/**
 * 道の駅 件数
 */
import Vue from 'vue'
import * as httpConst from '@/constants/httpConst'
import {BaseResult, StationNumber} from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/roadside_station_numbers`

interface RoadsideStationNumberResult extends BaseResult {
  content: {
    regions: StationNumber[]
  }
}

const roadsideStationNumber = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    index({}) {
      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH).then((data: RoadsideStationNumberResult) => {
          resolve(data.content.regions)
        })
      })
    },
  },
  mutations: {},
}

export default roadsideStationNumber
