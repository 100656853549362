/**
 * パスワード忘れ
 */
import Vue from 'vue'
import * as httpConst from '@/constants/httpConst'
import { BaseResult } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/forget`

interface CreateParam {
  login_id: string
}
interface UpdateParam {
  password: string
  password_confirmation: string
}

const forget = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    create({}, { params }: { params: CreateParam }) {
      return new Promise(resolve => {
        Vue.prototype.$http.post(API_PATH, params).then((data: BaseResult) => {
          resolve(data)
        })
      })
    },
    update({}, { token, params }: { token: string; params: UpdateParam }) {
      const api_path = `${API_PATH}/${token}`
      return new Promise(resolve => {
        Vue.prototype.$http.patch(api_path, params).then((data: BaseResult) => {
          resolve(data)
        })
      })
    },
  },
  mutations: {},
}

export default forget
