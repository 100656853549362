/**
 * snackbar
 */

import { Commit } from 'vuex'

export interface SnackbarState {
  isShow: boolean
  color: string
  message: string
}

const snackbar = {
  namespaced: true,
  state: {
    isShow: false,
    message: '',
    color: 'info',
  },
  getters: {},
  actions: {
    showInfo({ commit }: { commit: Commit }, message: string) {
      commit('showInfo', { message: message })
    },
    hide({ commit }: { commit: Commit }) {
      commit('hide')
    },
  },
  mutations: {
    showInfo(state: SnackbarState, message: string) {
      state.isShow = true
      state.message = message
      state.color = 'info'
    },
    showError(state: SnackbarState, message: string) {
      state.isShow = true
      state.message = message
      state.color = 'error'
    },
    hide(state: SnackbarState) {
      state.isShow = false
      state.message = ''
      state.color = 'info'
    },
  },
}

export default snackbar
