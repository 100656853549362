/**
 * loading
 */

export interface LoadingState {
  isLoading: boolean
}

const loading = {
  namespaced: true,
  state: {
    isLoading: false,
  },
  getters: {},
  actions: {},
  mutations: {
    started(state: LoadingState) {
      state.isLoading = true
    },
    finished(state: LoadingState) {
      state.isLoading = false
    },
  },
}

export default loading
