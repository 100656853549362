/**
 * 道の駅
 */
import Vue from 'vue'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, StationRegion, RoadsideStation } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/roadside_stations`

interface RoadsideStationResult extends BaseResult {
  content: {
    regions: StationRegion[]
  }
}
interface RoadsideStationQuery {
  areaName: string
  prefectureCode: number
}
interface RoadsideStationDetailResult extends BaseResult {
  content: RoadsideStation
}
interface HolidayResult extends BaseResult {
  content: {
    holidays: string[]
  }
}
interface StationProductResult extends BaseResult {
  content: {
    categories: string[]
  }
}

const roadsideStation = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    index({}, { params }: { params: RoadsideStationQuery }) {
      const parameter = {
        area_name: params.areaName,
        prefecture_code: params.prefectureCode === 0 ? null : params.prefectureCode,
      }

      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH, { params: parameter }).then((data: RoadsideStationResult) => {
          resolve(data.content.regions)
        })
      })
    },
    show({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: RoadsideStationDetailResult) => {
          resolve(data.content)
        })
      })
    },
    fetchHolidays({}, { id, targetDate }: { id: number; targetDate: string }) {
      const parameter = {
        target_date: targetDate,
      }
      const api_path = `${API_PATH}/${id}/holidays`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path, { params: parameter }).then((data: HolidayResult) => {
          resolve(data.content.holidays)
        })
      })
    },
    fetchProducts({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}/products`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: StationProductResult) => {
          resolve(data.content.categories)
        })
      })
    },
    fetchNumbers({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/numbers`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: StationProductResult) => {
          resolve(data.content.categories)
        })
      })
    },
  },
  mutations: {},
}

export default roadsideStation
