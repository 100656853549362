/**
 * 商品Q&A
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, Pagination } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/products`

interface Question {
  id: number
}
interface QuestionResult extends BaseResult {
  content: {
    questions: Question[]
    pagination: Pagination
  }
}

const productQuestion = {
  namespaced: true,
  state: {
    pagination: {
      is_last_page: true,
    },
  },
  getters: {},
  actions: {
    findQuestions({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}/questions`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: QuestionResult) => {
          resolve(data.content.questions)
        })
      })
    },
    create({ commit }: { commit: Commit }, { id, questionContent }: { id: number; questionContent: string }) {
      const api_path = `${API_PATH}/${id}/questions`

      const params = {
        question_content: questionContent,
      }

      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(api_path, params)
          .then((data: QuestionResult) => {
            commit('snackbar/showInfo', '登録しました', { root: true })
            const content = data.content
            resolve(content)
          })
          .catch(() => {
            reject()
          })
      })
    },
  },
  mutations: {},
}

export default productQuestion
