const rentalset = [
  {
    id: 7,
    to: { name: 'products-show', params: { id: 7 } },
    title: 'ソロキャンプセット (秋・冬)',
    summary: 'ソロキャンプデビューに最適！1人でも設営しやすいポールポケット式。',
    body: 'ソロキャンプデビューに最適！\n1人でも設営しやすいポールポケット式。\n横幅が210センチと広いから、男性でもゆったりと過ごる。\n収納・移動に便利なコンパクト収納。\n雨天時でも室内に直接雨が入り込まず、荷物やシューズ置き場に便利な前室空間。',
    price: 9200,
    imageId: 1,
  },
  {
    id: 5,
    to: { name: 'products-show', params: { id: 5 } },
    title: '2人用キャンプセット (秋・冬)',
    summary: '設営が簡単だからキャンプ初心者の方や、カップルにおすすめ！',
    body: '設営が簡単だからキャンプ初心者の方や、カップルにおすすめ！\n2人でも設営しやすいポールポケット式。\n前室を高くして開放感アップ。\nコンパクトサイズのドームテント。',
    price: 93394,
    imageId: 2,
  },
  {
    id: 3,
    to: { name: 'products-show', params: { id: 3 } },
    title: '3人用ファミリーキャンプセット (秋・冬)',
    summary: '家族や友人との3人でのキャンプに最適。簡単アシスト機能付きで初めてでも安心。',
    body: '初めての家族キャンプでも安心。\n家族3~4人がゆったり寝られるタフドーム。\n1人でも設営できる簡単アシスト機能付き\n強風でもびくともしないアルミ合金製メインポールを採用。',
    price: 156675,
    imageId: 3,
  },
  {
    id: 1,
    to: { name: 'products-show', params: { id: 1 } },
    title: '4人用ファミリーキャンプセット (秋・冬)',
    summary: '家族や友人との4人でのキャンプに最適。簡単アシスト機能付きで初めてでも安心。',
    body: '初めての家族キャンプでも安心。\n1人でも設営できる簡単アシスト機能付き。\n家族3~4人がゆったり寝られるタフドーム。\n強風でもびくともしないアルミ合金製メインポールを採用。',
    price: 182685,
    imageId: 4,
  },
]

export default {
  data() {
    return { rentalset }
  },
}
