import axios from 'axios'
import _Vue from 'vue'
import store from '@/store'

const host = process.env.VUE_APP_API_ENDPOINT

const client = axios.create({
  baseURL: host,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
  responseType: 'json',
  timeout: 50000,
  // 500系以外は正常として扱う（thenで処理できるようにする）
  // validateStatus: function (status) {
  //   return status < 500
  // }
})

export default {
  // install(Vue, { store }) {
  install(Vue: typeof _Vue) {
    client.interceptors.request.use(
      function (config) {
        store.commit('alertMessage/reset')
        store.commit('loading/started')
        return config
      },
      function (error) {
        store.commit('loading/finished')
        return Promise.reject(error)
      },
    )

    client.interceptors.response.use(
      function (response) {
        store.commit('loading/finished')
        return response.data
      },
      function (error) {
        if (process.env.NODE_ENV === 'development') {
          console.log('API error', error)
          console.log('API error response', error.response)
          console.log('API error message', error.message)
          console.log('API error message', error.response.status)
        }

        let message = 'エラーが発生しました'
        if (error.response.data !== undefined) {
          message = error.response.data.result === undefined ? 'エラーが発生しました' : error.response.data.result.message
          const error_code = error.response.data.result === undefined ? null : error.response.data.result.code
          if (error.response.status == 401 && error_code != 401001) {
            store.commit('auth/forceSignOut')
            store.commit('loading/finished')
            store.commit('snackbar/showError', message)
            return Promise.reject(error)
          }
        }

        store.commit('snackbar/showError', message)
        store.commit('alertMessage/showError', message)
        store.commit('loading/finished')

        return Promise.reject(error)
      },
    )

    // Vue.$http = Vue.prototype.$http = client
    Vue.prototype.$http = client
  },
}
