/**
 * alert message
 */

export interface AlertMessageState {
  isShow: boolean
  color: string
  message: string
}

const alertMessage = {
  namespaced: true,
  state: {
    isShow: false,
    color: 'red',
    message: '',
  },
  getters: {},
  actions: {},
  mutations: {
    reset(state: AlertMessageState) {
      state.isShow = false
      state.message = ''
    },
    // showError(state: AlertMessageState, { message: string }) {
    showError(state: AlertMessageState, message: string) {
      state.message = message
      state.color = 'red'
      state.isShow = true
    },
  },
}

export default alertMessage
