/**
 * カテゴリー
 */
import Vue from 'vue'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, Category, Tag } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/categories`

interface CategoryResult extends BaseResult {
  content: {
    categories: Category[]
  }
}

interface CategoryGroup {
  id: number
  name: string
  position: number
  tags: Tag[]
}

interface CategoryGroupResult extends BaseResult {
  content: {
    category_groups: CategoryGroup[]
  }
}
const category = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    index({}) {
      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH).then((data: CategoryResult) => {
          resolve(data.content.categories)
        })
      })
    },
    searchCategoryGroups({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}/category_groups`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: CategoryGroupResult) => {
          resolve(data.content.category_groups)
        })
      })
    },
  },
  mutations: {},
}

export default category
