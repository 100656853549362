/**
 * 身分証明書
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, Identification } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/customer/identifications`

interface IdentificationResult extends BaseResult {
  content: {
    identifications: Identification[]
  }
}

interface UpdateAttribute {
  id: number
  file: string
  _destroy: boolean
}

interface UpdateParam {
  identifications_attributes: UpdateAttribute[]
}

const identification = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    index({}) {
      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH).then((data: IdentificationResult) => {
          resolve(data.content.identifications)
        })
      })
    },
    update({ commit }: { commit: Commit }, { params }: { params: UpdateParam }) {
      return new Promise(resolve => {
        Vue.prototype.$http
          .post(API_PATH, params, { headers: { 'content-type': 'multipart/form-data' } })
          .then((data: IdentificationResult) => {
            commit('snackbar/showInfo', '登録しました', { root: true })
            resolve(data.content.identifications)
          })
      })
    },
    delete({ commit }: { commit: Commit }, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .delete(api_path)
          .then((data: IdentificationResult) => {
            commit('snackbar/showInfo', '削除しました', { root: true })
            resolve(data.content.identifications)
          })
          .catch(() => {
            reject()
          })
      })
    },
  },
  mutations: {},
}

export default identification
