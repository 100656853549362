/**
 * HOME
 * ランキング
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as types from '@/store/mutation-types/frontend-types'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, Tag } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/home/rankings`

interface Ranking {
  id: number
  name: string
  price: string
  in_tax_price: string
  image_url: string | null
  position: number
  tags: Tag[]
}

interface RankingResult extends BaseResult {
  content: {
    rankings: Ranking[]
  }
}

interface HomeRankingState {
  rankingCount: number
}

const homeRanking = {
  namespaced: true,
  state: {
    rankingCount: 0,
  },
  getters: {
    hasResult: (state: HomeRankingState) => {
      return state.rankingCount > 0
    },
  },
  actions: {
    index({ commit }: { commit: Commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH).then((data: RankingResult) => {
          commit(types.HOME_RANKING, data)
          resolve(data.content.rankings)
        })
      })
    },
  },
  mutations: {
    [types.HOME_RANKING](state: HomeRankingState, data: RankingResult) {
      state.rankingCount = data.content.rankings.length
    },
  },
}

export default homeRanking
