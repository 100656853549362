/**
 * HOME
 * 新着情報（TOPICS）
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as types from '@/store/mutation-types/frontend-types'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, FreePage } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/home/topics`

interface TopicResult extends BaseResult {
  content: {
    topics: FreePage[]
  }
}

interface HomeTopicState {
  topicCount: number
}

const homeTopic = {
  namespaced: true,
  state: {
    topicCount: 0,
  },
  getters: {
    hasResult: (state: HomeTopicState) => {
      return state.topicCount > 0
    },
  },
  actions: {
    index({ commit }: { commit: Commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH).then((data: TopicResult) => {
          commit(types.HOME_TOPIC, data)
          resolve(data.content.topics)
        })
      })
    },
  },
  mutations: {
    [types.HOME_TOPIC](state: HomeTopicState, data: TopicResult) {
      state.topicCount = data.content.topics.length
    },
  },
}

export default homeTopic
