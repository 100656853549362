/**
 * 店舗への問い合わせ
 */
import Vue from 'vue'
import * as types from '@/store/mutation-types/frontend-types'
import { Commit } from 'vuex'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, Pagination } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/tickets`

interface Ticket {
  id: number
}

interface TicketResult extends BaseResult {
  content: {
    tickets: Ticket[]
    pagination: Pagination
  }
}
interface TicketDetailResult extends BaseResult {
  content: Ticket
}

interface TicketState {
  pagination: Pagination
}

const customerTicket = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total_count: 0,
      limit_value: 20,
    },
  },
  getters: {},
  actions: {
    index({ commit }: { commit: Commit }, { page }: { page: number }) {
      const params = {
        page: page,
      }

      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH, { params: params }).then((data: TicketResult) => {
          commit(types.CUSTOMER_TICKET, { data })
          resolve(data.content.tickets)
        })
      })
    },
    create({ commit }: { commit: Commit }, { subject, comment }: { subject: string; comment: string }) {
      const params = {
        subject: subject,
        comment: comment,
      }
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(API_PATH, params)
          .then((data: TicketDetailResult) => {
            commit('snackbar/showInfo', '登録しました', { root: true })
            resolve(data.content)
          })
          .catch(() => {
            reject()
          })
      })
    },
    show({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: TicketDetailResult) => {
          resolve(data.content)
        })
      })
    },
  },
  mutations: {
    [types.CUSTOMER_TICKET](state: TicketState, { data }: { data: TicketResult }) {
      state.pagination = data.content.pagination
    },
  },
}

export default customerTicket
