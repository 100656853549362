/**
 * 認証
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as types from '@/store/mutation-types/frontend-types'
import * as httpConst from '@/constants/httpConst'
import { BaseResult } from '@/store/types/response-types'
import store from 'store'

const isFrontLoggedIn = () => {
  return store.get('cmpFrontLoggedIn')
}

interface User {
  name: string
  login_id: string
  password: string
}
interface AuthResult extends BaseResult {
  content: {
    access_token: string
    user: User
  }
}
interface SignInParam {
  login_id: string
  password: string
}

interface CurrentUser {
  name: string | null
  use_identification: boolean
}

const getCurrentUser = () => {
  const default_current_user: CurrentUser = {
    name: null,
    use_identification: false,
  }
  let current_user = null
  if (isFrontLoggedIn()) current_user = store.get('cmpCurrentUser')
  return current_user ? current_user : default_current_user
}

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}`

interface AuthState {
  loggedIn: boolean
  name: string
  useIdentification: boolean
}

/**
 * logged_in: Boolean (true: ログイン済)
 */
const auth = {
  namespaced: true,
  state: {
    loggedIn: isFrontLoggedIn(),
    name: getCurrentUser().name,
    useIdentification: getCurrentUser().use_identification,
  },
  getters: {
    avatarName: (state: AuthState) => {
      return state.name ? state.name.slice(0, 2) : ''
    },
    honorificName: (state: AuthState) => {
      return state.name ? `${state.name} さん` : ''
    },
  },
  actions: {
    signIn({ commit }: { commit: Commit }, { params }: { params: SignInParam }) {
      const api_path = `${API_PATH}/sign_in`
      return new Promise(resolve => {
        Vue.prototype.$http.post(api_path, params).then((data: AuthResult) => {
          const user = data.content.user
          user.login_id = params.login_id
          user.password = params.password
          commit(types.SIGN_IN, { user })
          resolve(data)
        })
      })
    },
    signOut({ commit }: { commit: Commit }) {
      const api_path = `${API_PATH}/sign_out`
      return new Promise(resolve => {
        Vue.prototype.$http.delete(api_path).then((data: BaseResult) => {
          commit(types.SIGN_OUT)
          resolve(data)
        })
      })
    },
  },
  mutations: {
    forceSignOut(state: AuthState) {
      state.loggedIn = false
      store.remove('cmpFrontLoggedIn')
      store.remove('cmpCurrentUser')
    },
    [types.SIGN_IN](state: AuthState, { user }: { user: User }) {
      state.loggedIn = true
      state.name = user.name
      // localStorageに保持
      store.set('cmpFrontLoggedIn', true)
      store.set('cmpCurrentUser', user)
    },
    [types.SIGN_OUT](state: AuthState) {
      state.loggedIn = false
      // localStorageから削除
      store.remove('cmpFrontLoggedIn')
      store.remove('cmpCurrentUser')
    },
  },
}

export default auth
