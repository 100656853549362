/**
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as httpConst from '@/constants/httpConst'
import store from 'store'

const isFrontLoggedIn = () => {
  return store.get('cmpFrontLoggedIn')
}

const getCurrentUser = () => {
  let current_user = null
  if (isFrontLoggedIn()) current_user = store.get('cmpCurrentUser')
  return current_user
}

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/rakuten_id_connect_tying`

const idConnectTying = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    show({ commit }: { commit: Commit }, { code }: { code: string }) {
      const params = {
        code: code,
        login_id: getCurrentUser().login_id,
        password: getCurrentUser().password
      }

      const api_path = `${API_PATH}`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path, { params: params }).then(() => {
          commit('snackbar/showInfo', '楽天IDと紐付けました。', { root: true })
          resolve(true)
        })
      })
    },
    release({ commit }: { commit: Commit }) {
      const api_path = `${API_PATH}`
      return new Promise(resolve => {
        Vue.prototype.$http.delete(api_path).then(() => {
          commit('snackbar/showInfo', '楽天IDとの紐付けを解除しました。', { root: true })
          resolve(true)
        })
      })
    },
  },
}

export default idConnectTying
