/**
 * 問い合わせ(inquery)
 */
import Vue from 'vue'
import * as httpConst from '@/constants/httpConst'
import { BaseResult} from '@/store/types/response-types'
 
const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/inquiry`
interface CreateParam {
  order_id: string,
  name: string
  name_kana: string
  phone_number: string
  email: string
  inquiry_type: string
  message: string
}

const inquery = {
  namespaced: true,
  actions: {
    create({}, { params }: { params: CreateParam }) {
      return new Promise(resolve => {
        Vue.prototype.$http.post(API_PATH, params).then((data: BaseResult) => {
          resolve(data)
        })
      })
    },
  }
}
 
export default inquery
 