/**
 * 店舗への問い合わせ（コメント）
 */
import Vue from 'vue'
import * as types from '@/store/mutation-types/frontend-types'
import { Commit } from 'vuex'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, Pagination } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/tickets`

interface TicketComment {
  id: number
}

interface TicketCommentResult extends BaseResult {
  content: {
    ticket_comments: TicketComment[]
    pagination: Pagination
  }
}

interface TicketCommentDetialResult extends BaseResult {
  content: TicketComment
}

interface TicketCommentState {
  pagination: Pagination
}

const customerTicketComment = {
  namespaced: true,
  state: {
    pagination: {
      total_count: 0,
    },
  },
  getters: {},
  actions: {
    index({ commit }: { commit: Commit }, { id, page }: { id: number; page: number }) {
      const params = {
        page: page,
      }
      const api_path = `${API_PATH}/${id}/comments`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path, { params: params }).then((data: TicketCommentResult) => {
          commit(types.TICKET_COMMENT, { data })
          resolve(data.content.ticket_comments)
        })
      })
    },
    create({ commit }: { commit: Commit }, { id, comment }: { id: number; comment: string }) {
      const params = {
        comment: comment,
      }
      const api_path = `${API_PATH}/${id}/comments`
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(api_path, params)
          .then((data: TicketCommentDetialResult) => {
            commit('snackbar/showInfo', '登録しました', { root: true })
            resolve(data.content)
          })
          .catch(() => {
            reject()
          })
      })
    },
  },
  mutations: {
    [types.TICKET_COMMENT](state: TicketCommentState, { data }: { data: TicketCommentResult }) {
      state.pagination = data.content.pagination
    },
  },
}

export default customerTicketComment
