/**
 * HOME
 * ピックアップ
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as types from '@/store/mutation-types/frontend-types'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, Tag } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/home/pickups`

interface Pickup {
  id: number
  name: string
  price: string
  in_tax_price: string
  image_url: string | null
  position: number
  tags: Tag[]
}

interface PickupResult extends BaseResult {
  content: {
    pickups: Pickup[]
  }
}

interface HomePickupState {
  pickupCount: number
}

const homePickup = {
  namespaced: true,
  state: {
    pickupCount: 0,
  },
  getters: {
    hasResult: (state: HomePickupState) => {
      return state.pickupCount > 0
    },
  },
  actions: {
    index({ commit }: { commit: Commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH).then((data: PickupResult) => {
          commit(types.HOME_PICKUP, data)
          resolve(data.content.pickups)
        })
      })
    },
  },
  mutations: {
    [types.HOME_PICKUP](state: HomePickupState, data: PickupResult) {
      state.pickupCount = data.content.pickups.length
    },
  },
}

export default homePickup
