/**
 * 商品
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as types from '@/store/mutation-types/frontend-types'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, Pagination, ProductQuery, CompareColumn } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/products`

interface Product {
  id: number
}

interface ProductResult extends BaseResult {
  content: {
    products: Product[]
    pagination: Pagination
  }
}
interface CompareResult extends BaseResult {
  content: {
    compare_columns: CompareColumn[]
    products: Product[]
  }
}

interface ProductDetailResult extends BaseResult {
  content: Product
}

interface ProductState {
  query: ProductQuery
  pagination: Pagination
}

const product = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total_count: 0,
    },
    query: {
      keyword: '',
      categoryId: null,
      categoryName: '',
      tagIds: [],
      page: 1,
      limit: 20,
    } as ProductQuery,
  },
  getters: {
    hasProducts: (state: ProductState) => {
      return state.pagination.total_count > 0
    },
    hasPrev: (state: ProductState) => {
      return state.pagination.prev_page > 0
    },
    hasNext: (state: ProductState) => {
      return state.pagination.next_page > 0
    },
  },
  actions: {
    index({ commit }: { commit: Commit }, { params }: { params: ProductQuery }) {

      const parameter = new URLSearchParams();
      let categoryGroupId = ""    // カテゴリーグループID
      let tagIds = ""             // タグID（複数）
      params.tagIds = params.tagIds.sort()
      for (let i=0; i<params.tagIds.length; i++) {

        // カテゴリーグループが変わったら
        if ((categoryGroupId!="" && categoryGroupId != params.tagIds[i].split('-').slice(0, 1).toString())) {
          if (tagIds != "") parameter.append("tag_ids[]", tagIds)
          tagIds = ""
        }

        // カテゴリーグループID保持
        categoryGroupId = params.tagIds[i].split('-').slice(0, 1).toString()

        // タグIDを保持
        if (tagIds != "") tagIds += ","
        tagIds += params.tagIds[i].split('-').slice(-1).toString()

      }

      if (tagIds != "") parameter.append("tag_ids[]", tagIds)
      parameter.append("keyword_text", params.keyword)
      if (params.categoryId != null) parameter.append("category_id", params.categoryId.toString())
      parameter.append("page", params.page.toString())
      parameter.append("limit", params.limit.toString())

      commit(types.PRODUCT_QUERY, params)

      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH, { params: parameter }).then((data: ProductResult) => {
          commit(types.PRODUCT, data)
          resolve(data.content.products)
        })
      })
    },
    show({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: ProductDetailResult) => {
          resolve(data.content)
        })
      })
    },
    findRelations({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}/product_relations`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: ProductResult) => {
          resolve(data.content.products)
        })
      })
    },
    findCompares({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}/compares`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: CompareResult) => {
          resolve(data.content)
        })
      })
    },
  },
  mutations: {
    [types.PRODUCT](state: ProductState, data: ProductResult) {
      state.pagination = data.content.pagination
    },
    [types.PRODUCT_QUERY](state: ProductState, params: ProductQuery) {
      state.query.categoryId = params.categoryId
      state.query.tagIds = params.tagIds
      state.query.keyword = params.keyword
      state.query.limit = params.limit
      state.query.page = params.page
    },
  },
}

export default product
