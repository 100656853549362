/**
 * HOME
 * カテゴリー
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as types from '@/store/mutation-types/frontend-types'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, Category } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/home/categories`

interface CategoryResult extends BaseResult {
  content: {
    categories: Category[]
  }
}

interface HomeCategoryState {
  categoryCount: number
}

const homeCategory = {
  namespaced: true,
  state: {
    categoryCount: 0,
  },
  getters: {
    hasResult: (state: HomeCategoryState) => {
      return state.categoryCount > 0
    },
  },
  actions: {
    index({ commit }: { commit: Commit }) {
      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH).then((data: CategoryResult) => {
          commit(types.HOME_CATEGORY, data)
          resolve(data.content.categories)
        })
      })
    },
  },
  mutations: {
    [types.HOME_CATEGORY](state: HomeCategoryState, data: CategoryResult) {
      state.categoryCount = data.content.categories.length
    },
  },
}

export default homeCategory
