/**
 * 旬のキャンプ情報
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as types from '@/store/mutation-types/frontend-types'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, FreePage, Pagination } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/news`

interface NewsResult extends BaseResult {
  content: {
    news: FreePage[]
    pagination: Pagination
  }
}

interface NewsState {
  pagination: Pagination
}

const news = {
  namespaced: true,
  state: {
    pagination: {},
  },
  getters: {
    hasNews: (state: NewsState) => {
      return state.pagination.total_count > 0
    },
    hasPrev: (state: NewsState) => {
      return state.pagination.prev_page > 0
    },
    hasNext: (state: NewsState) => {
      return state.pagination.next_page > 0
    },
  },
  actions: {
    index({ commit }: { commit: Commit }, { page }: { page: number }) {
      const params = {
        page: page,
        limit: 6,
      }

      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH, { params: params }).then((data: NewsResult) => {
          commit(types.NEWS, data)
          resolve(data.content.news)
        })
      })
    },
    show({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: NewsResult) => {
          resolve(data.content)
        })
      })
    },
    recent_posts({}) {
      return new Promise(resolve => {
        const api_path = `${API_PATH}/recent_posts`
        Vue.prototype.$http.get(api_path).then((data: NewsResult) => {
          resolve(data.content.news)
        })
      })
    },
  },
  mutations: {
    [types.NEWS](state: NewsState, data: NewsResult) {
      state.pagination = data.content.pagination
    },
  },
}

export default news
