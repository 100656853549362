/**
 * キャンプ場 件数
 */
import Vue from 'vue'
import * as httpConst from '@/constants/httpConst'
import {BaseResult, StationRegion, RoadsideStation, CampsiteNumber} from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/camp_site_numbers`

interface CampSiteNumberResult extends BaseResult {
  content: {
    camp_sites: CampsiteNumber[]
  }
}

const campsiteNumber = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    index({}) {
      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH).then((data: CampSiteNumberResult) => {
          resolve(data.content.camp_sites)
        })
      })
    },
  },
  mutations: {},
}

export default campsiteNumber
