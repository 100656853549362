import Vue from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'

/**
 * @see https://qiita.com/shunjikonishi/items/3774486d37af80d1ae47
 */
export default Vue.extend({
  data() {
    return {
      head: {
        link: '',
        title: '',
        separator: '|',
        description: '',
        image: '',
      },
    }
  },
  watch: {
    'head.title': function () {
      this.$emit('updateHead')
    },
  },
  head: {
    title() {
      return {
        inner: this.head.title,
        separator: this.head.separator === '' ? ' ' : '|',
        complement: this.head.separator === '' ? ' ' : 'キャンプ用品レンタルならCAMP GO RENTAL（キャンプゴーレンタル）',
      }
    },
    meta() {
      return [
        { n: 'description', c: this.head.description, id: 'description' },
        { n: 'twitter:title', c: this.head.title, id: 'twitter:title' },
        { n: 'twitter:description', c: this.head.description, id: 'twitter:description' },
        { p: 'og:image', c: require('@/assets/OGP.png'), id: 'og:image' },
      ]
    },
    link() {
      return [{ rel: 'canonical', href: this.head.link, id: 'canonical' }]
    },
  },
} as ThisTypedComponentOptionsWithRecordProps<
  Vue,
  {
    head: {
      link: string
      title: string
      separator: string
      description: string
      image: string
    }
  },
  unknown,
  unknown,
  Readonly<Record<keyof Vue, any>>
>)
