/**
 * キャンプ場
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as types from '@/store/mutation-types/frontend-types'
import * as httpConst from '@/constants/httpConst'
import { BaseResult, Pagination } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/camp_sites`

interface CampSite {
  id: number
}
interface CampSiteResult extends BaseResult {
  content: {
    camp_sites: CampSite[]
    pagination: Pagination
  }
}
interface CampSiteDetailResult extends BaseResult {
  content: CampSite
}

interface CampSiteState {
  pagination: Pagination
}

interface CampSiteQuery {
  siteName: string
  prefectureCode: number
  page: number
  limit: number
}

const campsite = {
  namespaced: true,
  state: {
    pagination: {},
  },
  getters: {
    hasCampSite: (state: CampSiteState) => {
      return state.pagination.total_count > 0
    },
    hasPrev: (state: CampSiteState) => {
      return state.pagination.prev_page > 0
    },
    hasNext: (state: CampSiteState) => {
      return state.pagination.next_page > 0
    },
  },
  actions: {
    index({ commit }: { commit: Commit }, { params }: { params: CampSiteQuery }) {
      const parameter = {
        name: params.siteName,
        prefecture_code: params.prefectureCode === 0 ? null : params.prefectureCode,
        page: params.page,
        limit: params.limit,
      }

      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH, { params: parameter }).then((data: CampSiteResult) => {
          commit(types.CAMP_SITE, data)
          resolve(data.content.camp_sites)
        })
      })
    },
    show({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: CampSiteDetailResult) => {
          resolve(data.content)
        })
      })
    },
    createCartDelivery({ commit }: { commit: Commit }, { id }: { id: number }) {
      const api_path = `${API_PATH}/${id}/cart_delivery`
      return new Promise(resolve => {
        Vue.prototype.$http.post(api_path).then((data: BaseResult) => {
          commit('snackbar/showInfo', '登録しました', { root: true })
          resolve(data)
        })
      })
    },
  },
  mutations: {
    [types.CAMP_SITE](state: CampSiteState, data: CampSiteResult) {
      state.pagination = data.content.pagination
    },
  },
}

export default campsite
