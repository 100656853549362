import Vue from 'vue'
import moment from 'moment'

/**
 * カンマフォーマット
 */
Vue.filter('comma_format', number => {
  if (!number) return number
  return number.toLocaleString()
})

/**
 * 円表記フォーマット
 */
Vue.filter('yen_format', number => {
  if (!number) return number
  return `${number.toLocaleString()}円`
})
Vue.filter('before_yen_format', number => {
  if (!number) return number
  return `¥${number.toLocaleString()}`
})

/**
 * 円表記フォーマット
 * isTaxExempt=true(非課税)以外の場合は（税込）を付与
 * @param {Int} number
 * @param {Boolean} isTaxExempt
 * @returns {String}
 */
Vue.filter('in_tax_yen_format', (number, isTaxExempt) => {
  if (!number) return number
  let value = `¥${number.toLocaleString()}`
  if (!isTaxExempt) value = `${value}(税込)`
  return value
})

/**
 * 日付フォーマット
 */
Vue.filter('date_format', date => {
  if (!date) return date
  return moment(date).format('YYYY/MM/DD')
})

/**
 * 日付フォーマット
 */
Vue.filter('from_to_date_format', function (from, to) {
  const from_date = from ? moment(from).format('YYYY/MM/DD') : ''
  const to_date = to ? moment(to).format('YYYY/MM/DD') : ''
  if (from_date != '' || to_date != '') {
    return from_date + '〜' + to_date
  } else {
    return ''
  }
})

/**
 * Unixタイムフォーマット
 */
Vue.filter('unix_datetime_format', number => {
  if (!number) return number
  return moment.unix(number).format('YYYY/MM/DD HH:mm')
})

/**
 * Unixタイムフォーマット
 */
Vue.filter('unix_timestamp_format', number => {
  if (!number) return number
  return moment.unix(number).format('YYYY/MM/DD')
})

/**
 * Unixタイムフォーマット
 */
Vue.filter('unix_timestamp_format_period', number => {
  if (!number) return number
  return moment.unix(number).format('YYYY.MM.DD')
})

Vue.filter('payment_label', value => {
  if (!value) return ''
  let label = null
  switch (value) {
    case 'one_time_payment':
      label = 'レンタル'
      break
    case 'subscription_payment':
      label = '定期課金'
      break
    case 'sale_payment':
      label = '販売'
      break
  }
  return label
})

/**
 * 画像URL
 */
Vue.filter('load_image_url', url => {
  if (!url) return require('../assets/no-image.png')
  return url
})

/**
 * 文字数を制限して余分があれば省略して末尾...に置換する
 * @param {String} text
 * @param {Number} length
 * @param {String} clamp
 * @returns {String}
 */
Vue.filter('truncate', (text, length, clamp) => {
  text = text || ''
  clamp = clamp || '...'
  length = length || 30

  if (text.length <= length) {
    return text
  }
  return text.substring(0, length) + clamp
})

/**
 * レンタル拍数
 */
Vue.filter('rental_night_format', days => {
  const number = Number(days)
  if (!Number.isInteger(number)) return
  if (number <= 0) return
  return `（${number}泊${number + 1}日レンタル）`
})

Vue.filter('stationTimeFilter', value => {
  return value === 'am' ? '午前' : '午後'
})
