/**
 * 配送希望時間
 */
import Vue from 'vue'
import * as httpConst from '@/constants/httpConst'
import { BaseResult } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/delivery_desired_times`

interface DeliveryDesiredTime {
  id: number
  desired_time_value: string
}
interface DeliveryDesiredTimeResult extends BaseResult {
  content: {
    default_delivery_desired_time_id: number | null
    delivery_desired_times: DeliveryDesiredTime[]
  }
}

const deliveryDesiredTime = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    index({}) {
      return new Promise(resolve => {
        Vue.prototype.$http.get(API_PATH).then((data: DeliveryDesiredTimeResult) => {
          resolve(data.content)
        })
      })
    },
  },
  mutations: {},
}

export default deliveryDesiredTime
