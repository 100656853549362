/**
 * 商品レビュー
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as httpConst from '@/constants/httpConst'
import * as types from '@/store/mutation-types/frontend-types'
import { BaseResult, Pagination } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/products`

interface Review {
  id: number
}

interface ReviewResult extends BaseResult {
  content: {
    reviews: Review[]
    pagination: Pagination
  }
}
interface ReviewDetailResult extends BaseResult {
  content: Review
}

interface ReviewState {
  pagination: Pagination
}

interface RegistrationParam {
  id: number
  title: string
  comment: string
  evaluation: number
  bundle_name: string
}

const product_review = {
  namespaced: true,
  state: {
    pagination: {
      is_last_page: true,
    },
  },
  getters: {},
  actions: {
    findReviews({ commit }: { commit: Commit }, { product_id, page }: { product_id: number; page: number }) {
      const params = {
        page: page,
      }
      const api_path = `${API_PATH}/${product_id}/reviews`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path, { params: params }).then((data: ReviewResult) => {
          const content = data.content
          commit(types.PRODUCT_REVIEW, { content })
          resolve(content)
        })
      })
    },
    searchReview({}, { product_id }: { product_id: number }) {
      const api_path = `${API_PATH}/${product_id}/search_review`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: ReviewDetailResult) => {
          const content = data.content
          resolve(content)
        })
      })
    },
    create({ commit }: { commit: Commit }, { product_id, params }: { product_id: number; params: RegistrationParam }) {
      const api_path = `${API_PATH}/${product_id}/reviews`
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .post(api_path, params)
          .then((data: ReviewDetailResult) => {
            commit('snackbar/showInfo', '登録しました', { root: true })
            const content = data.content
            resolve(content)
          })
          .catch(() => {
            reject()
          })
      })
    },
    update({ commit }: { commit: Commit }, { product_id, params }: { product_id: number; params: RegistrationParam }) {
      const api_path = `${API_PATH}/${product_id}/reviews/${params['id']}`
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .patch(api_path, params)
          .then((data: ReviewDetailResult) => {
            commit('snackbar/showInfo', '登録しました', { root: true })
            const content = data.content
            resolve(content)
          })
          .catch(() => {
            reject()
          })
      })
    },
  },
  mutations: {
    [types.PRODUCT_REVIEW](state: ReviewState, data: ReviewResult) {
      state.pagination = data.content.pagination
    },
  },
}

export default product_review
