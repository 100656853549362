import homeNews from '@/store/modules/frontend/home/news'
import homeRanking from '@/store/modules/frontend/home/ranking'
import homePickup from '@/store/modules/frontend/home/pickup'
import homeCategory from '@/store/modules/frontend/home/category'
import homeTopic from '@/store/modules/frontend/home/topic'
import customer from '@/store/modules/frontend/customer'
import auth from '@/store/modules/frontend/auth'
import forget from '@/store/modules/frontend/forget'
import news from '@/store/modules/frontend/news'
import topic from '@/store/modules/frontend/topic'
import product from '@/store/modules/frontend/product'
import category from '@/store/modules/frontend/category'
import productQuestion from '@/store/modules/frontend/product/question'
import campsite from '@/store/modules/frontend/campsite'
import roadsideStation from '@/store/modules/frontend/roadside-station'
import identification from '@/store/modules/frontend/customer/identification'
import customerNotice from '@/store/modules/frontend/customer/notice'
import customerTicket from '@/store/modules/frontend/customer/ticket'
import customerTicketComment from '@/store/modules/frontend/customer/ticket-comment'
import cart from '@/store/modules/frontend/cart'
import deliveryDesiredTime from '@/store/modules/frontend/delivery_desired_time'
import order from '@/store/modules/frontend/order'
import product_review from '@/store/modules/frontend/product_review'
import inquiry from '@/store/modules/frontend/inquiry'
import roadsideStationNumber from "@/store/modules/frontend/roadside-station_number";
import campsiteNumber from "@/store/modules/frontend/campsite_number";
import idConnect from '@/store/modules/frontend/rakuten_id_connect'
import idConnectTying from '@/store/modules/frontend/rakuten_id_connect_tying'

const frontendModules = {
  modules: {
    homeNews,
    homeRanking,
    homePickup,
    homeCategory,
    homeTopic,
    customer,
    auth,
    forget,
    news,
    topic,
    product,
    category,
    productQuestion,
    campsite,
    campsiteNumber,
    roadsideStation,
    roadsideStationNumber,
    identification,
    customerNotice,
    customerTicket,
    customerTicketComment,
    cart,
    deliveryDesiredTime,
    order,
    product_review,
    inquiry,
    idConnect,
    idConnectTying,
  },
}

export default frontendModules
