/**
 * 注文
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as httpConst from '@/constants/httpConst'
import * as types from '@/store/mutation-types/frontend-types'
import { BaseResult, Pagination } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/orders`

interface OrderQuery {
  page: number
  limit: number
}

interface OrderDetail {
  id: number
}

interface OrderResult extends BaseResult {
  content: {
    order_details: OrderDetail[]
    pagination: Pagination
  }
}
interface OrderDetailResult extends BaseResult {
  content: OrderDetail
}

interface OrderState {
  pagination: Pagination
}

const order = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
    },
    query: {
      page: 1,
      limit: 20,
    } as OrderQuery,
  },
  getters: {
    isOrder: (state: OrderState) => {
      return state.pagination.total_count > 0
    },
  },
  actions: {
    index({ commit }: { commit: Commit }, { params }: { params: OrderQuery }) {
      const api_path = `${API_PATH}/histories`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path, { params: params }).then((data: OrderResult) => {
          const content = data.content
          commit(types.ORDER_DETAIL, { content })
          resolve(content)
        })
      })
    },
    show({}, { id }: { id: number }) {
      const api_path = `${API_PATH}/histories/${id}`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: OrderDetailResult) => {
          resolve(data.content)
        })
      })
    },
    sendExtendMail({ commit }: { commit: Commit }, { order_id, subject, body }: { order_id: number; subject: string; body: string }) {
      const api_path = `${API_PATH}/extends/${order_id}`
      const params = {
        subject: subject,
        body: body,
      }
      return new Promise(resolve => {
        Vue.prototype.$http.put(api_path, params).then((data: BaseResult) => {
          commit('snackbar/showInfo', '送信しました', { root: true })
          resolve(data)
        })
      })
    },
  },
  mutations: {
    [types.ORDER_DETAIL](state: OrderState, data: OrderResult) {
      state.pagination = data.content.pagination
    },
  },
}

export default order
