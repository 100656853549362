/**
 * 会員情報
 */
import Vue from 'vue'
import { Commit } from 'vuex'
import * as httpConst from '@/constants/httpConst'
import { BaseResult } from '@/store/types/response-types'

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/customer`

interface Customer {
  id: number
  name: string
  name_kana: string
  bundle_name: string
  gender: string
  zip_code: string
  prefecture_code: string
  address: string
  phone: string
  email: string
  birthday: string
  login_id: string
  point: number | null
  activated_at: number | null
  can_order: boolean
}
interface CustomerResult extends BaseResult {
  content: Customer
}

const customer = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    new({}, { code }: { code: string }) {
      const parameter = {
        code: code,
      }
      const api_path = `${API_PATH}/new`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path, {params: parameter}).then((data: CustomerResult) => {
          resolve(data.content)
        })
      })
    },
    show() {
      const api_path = `${API_PATH}`
      return new Promise(resolve => {
        Vue.prototype.$http.get(api_path).then((data: CustomerResult) => {
          resolve(data.content)
        })
      })
    },
    create({ commit }: { commit: Commit }, { params }: { params: Customer }) {
      return new Promise(resolve => {
        Vue.prototype.$http.post(API_PATH, params).then((data: CustomerResult) => {
          commit('snackbar/showInfo', '登録しました', { root: true })
          resolve(data.content)
        })
      })
    },
    update({ commit }: { commit: Commit }, { params }: { params: Customer }) {
      return new Promise(resolve => {
        Vue.prototype.$http.put(API_PATH, params).then((data: CustomerResult) => {
          commit('snackbar/showInfo', '更新しました', { root: true })
          resolve(data.content)
        })
      })
    },
    activate({}, { token }: { token: string }) {
      const api_path = `${API_PATH}/activate`
      const parameter = { activate_token: token }
      return new Promise(resolve => {
        Vue.prototype.$http.put(api_path, parameter).then((data: CustomerResult) => {
          resolve(data.content)
        })
      })
    },
  },
  mutations: {},
}

export default customer
