/**
 * vee-validate
 * https://logaretm.github.io/vee-validate/guide/rules.html#rules
 */
import { ValidationObserver, ValidationProvider, localize, extend } from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja.json'
import * as rules from 'vee-validate/dist/rules'
import Vue from 'vue'
import moment from 'moment'

// VeeValidateが用意している全てのルールを適用
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

// カスタムバリデーション
extend('zip_code7', {
  params: [],
  validate(value) {
    // let regex = /^[0-9]{3}[-][0-9]{4}$/
    let regex = /^[0-9]{3}[0-9]{4}$/
    return regex.test(value)
  },
  message: '{_field_}のフォーマットが正しくありません',
})

extend('station_date', {
  params: ['from', 'to'],
  validate: (value, params) => {
    const fromDate = moment(params['from'])
    const toDate = moment(params['to'])
    if (!fromDate || !toDate) return false

    const targetDate = moment(value)
    return targetDate.isSameOrAfter(fromDate) && targetDate.isSameOrBefore(toDate)
  },
  message: '{_field_}は、レンタル期間内を入力してください',
})

extend('before_date', {
  params: ['target'],
  validate: (value, params) => {
    const target = params['target']
    const fromDate = moment(value)
    const toDate = moment(target)
    if (!fromDate || !toDate) return false
    return fromDate.isBefore(toDate)
  },
  message: '{_field_}は、{target}より前の日付を入力ください',
})

extend('after_date', {
  params: ['target'],
  validate: (value, params) => {
    const target = params['target']
    const fromDate = moment(value)
    const toDate = moment(target)
    if (!fromDate || !toDate) return false
    return fromDate.isAfter(toDate)
  },
  message: '{_field_}は、{target}より後の日付を入力ください',
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
localize('ja', ja)
