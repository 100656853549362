export const HOME_NEWS = 'HOME_NEWS'
export const HOME_RANKING = 'HOME_RANKING'
export const HOME_PICKUP = 'HOME_PICKUP'
export const HOME_CATEGORY = 'HOME_CATEGORY'
export const HOME_TOPIC = 'HOME_TOPIC'
export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const NEWS = 'NEWS'
export const PRODUCT = 'PRODUCT'
export const PRODUCT_QUERY = 'PRODUCT_QUERY'
export const CAMP_SITE = 'CAMP_SITE'
export const CUSTOMER_NOTICE = 'CUSTOMER_NOTICE'
export const CUSTOMER_TICKET = 'CUSTOMER_TICKET'
export const TICKET_COMMENT = 'TICKET_COMMENT'
export const CART = 'CART'
export const CART_COUNT = 'CART_COUNT'
export const PAYMENT_CUSTOMER = 'PAYMENT_CUSTOMER'
export const RESET_PAYMENT_CUSTOMER = 'RESET_PAYMENT_CUSTOMER'
export const ORDER_DETAIL = 'ORDER_DETAIL'
export const PRODUCT_REVIEW = 'PRODUCT_REVIEW'
